
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import Range from '@/modules/common/components/ui-kit/range.vue';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import RankingModel from '@/modules/ranking/models/ranking-document.model';
import ClusterRankingService, { ClusterRankingServiceS } from '@/modules/cluster/cluster-ranking.service';

@Component({
    components: { Range },
})
export default class RankingChainProvider extends Vue {
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;
    @Inject(ClusterRankingServiceS) private clusterRankingService!: ClusterRankingService;

    @Prop(String)
    compsetId!: string;

    @Prop(String)
    provider!: string;

    @Prop(Number)
    hotelId?: number;

    @Prop(Object)
    compsetMain?: RankingModel;

    readonly newReviews: number = 30;

    get rankingData() {
        if (!this.compsetMain) {
            return null;
        }
        if (!this.hotelId) {
            return null;
        }
        if (!this.compsetMain.providers[this.provider]) {
            return null;
        }
        if (!this.compsetMain.providers[this.provider][this.hotelId]) {
            return null;
        }
        return this.compsetMain.providers[this.provider][this.hotelId];
    }

    get maxScore() {
        return this.providersService.getMaxScore(this.provider) || 5;
    }

    get minMaxRatings() {
        if (!this.compsetMain) {
            return null;
        }
        return this.clusterRankingService.minMaxCompetitiveRating(this.compsetMain, this.provider, this.compsetId);
    }

    get minMaxReviews() {
        if (!this.compsetMain) {
            return null;
        }
        return this.clusterRankingService.minMaxCompetitiveReview(this.compsetMain, this.provider, this.compsetId);
    }

    get scoreMinScale() {
        if (this.rankingData === null) {
            return 0;
        }

        const leftOffset = Math.min(this.scoreLowest, this.rankingData.rating);
        return Math.max(Math.floor(leftOffset * 0.8), 0);
    }

    get scoreMaxScale() {
        if (this.rankingData === null) {
            return 1;
        }

        const rightOffset = Math.max(this.scoreHighest, this.rankingData.rating);
        return Math.min(Math.round(rightOffset * 1.4), this.maxScore);
    }

    get scoreLowest() {
        return this.minMaxRatings ? this.minMaxRatings[0] : 0;
    }

    get scoreHighest() {
        return this.minMaxRatings ? this.minMaxRatings[1] : 0;
    }

    get reviewMinScale() {
        if (this.rankingData === null) {
            return 0;
        }

        const leftOffset = Math.min(this.reviewLowest, this.rankingData.reviews);
        return Math.max(Math.floor(leftOffset * 0.3), 0);
    }

    get reviewMaxScale() {
        if (this.rankingData === null) {
            return 1;
        }

        const rightOffset = Math.max(this.reviewHighest, this.rankingData.reviews);
        return Math.max(Math.round(rightOffset * 1.3), 1);
    }

    get reviewLowest() {
        return this.minMaxReviews ? this.minMaxReviews[0] : 0;
    }

    get reviewHighest() {
        return this.minMaxReviews ? this.minMaxReviews[1] : 0;
    }
}
