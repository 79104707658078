
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ExcelBtn from '@/modules/common/components/excel-btn.vue';
import ClusterRankingService, { ClusterRankingServiceS } from '../../cluster-ranking.service';

@Component({
    components: {
        ExcelBtn,
    },
})
export default class RankingClusterActions extends Vue {
    @Inject(ClusterRankingServiceS) private clusterRankingService!: ClusterRankingService;

    downloadAction() {
        return this.clusterRankingService.downloadExcel();
    }
}
